import React from "react"
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Display from "../components/slider"
import Header from "../components/header"
import Footer from "../components/footer"
import "../styles/style.css"

const Empleo = () => {
  const data = useStaticQuery(graphql`
    query Images_compania {
      image: allFile(
        filter: { relativeDirectory: { eq: "compania" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      },
      logos: allFile(
        filter: { relativeDirectory: { eq: "offering/logos" } }
        sort: { fields: name, order: ASC }
      ) {
        nodes {
          id
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `)
  return (
    <div>
      <Helmet>
        {/*Primary Meta Tags*/}
        <title>Nuestra Empresa | Qualoom</title>
        <meta name="title" content="Nuestra Empresa | Qualoom"/>
        <meta name="description" content="Partner Advanced en AWS con servicios DevOps, SysOps, SysAdmin, migración a la nube, Cloud híbrida, optimización de rendimiento y escalabilidad."/>
        <html lang='es'/>        
        <link rel="alternate" href="https://www.qualoom.es/compania/"/>
        <link rel="canonical" href="https://www.qualoom.es/compania/"/>
        <meta name="ahrefs-site-verification" content="11d569467b4aa0b0c9daa5b49b9f41eb8a413858d36c9f6ece089482f4d3b630"/>

        {/*Open Graph / Facebook*/}
        <meta property="og:locale" content="es_ES"/>
        <meta property="og:type" content="website"/>
        <meta property="og:url" content="https://www.qualoom.es/compania/"/>
        <meta property="og:title" content="Nuestra Empresa | Qualoom"/>
        <meta property="og:description" content="Partner Advanced en AWS con servicios DevOps, SysOps, SysAdmin, migración a la nube, Cloud híbrida, optimización de rendimiento y escalabilidad."/>
        <meta property="og:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qi1jmQ9dtzCaBSr1oYlaN/6fdf09118119e42072bba6aee65ce8aa/tw_cards_compania.png"/>
        <meta property="og:image:width" content="1200"/>
        <meta property="og:image:height" content="628"/>

        {/*Twitter*/}
        <meta property="twitter:card" content="summary_large_image"/>
        <meta property="twitter:type" content="website"/>
        <meta property="twitter:url" content="https://www.qualoom.es/compania/"/>
        <meta property="twitter:title" content="Nuestra Empresa | Qualoom"/>
        <meta property="twitter:image" content="https://images.ctfassets.net/wy4ivoo9i8ge/3qi1jmQ9dtzCaBSr1oYlaN/6fdf09118119e42072bba6aee65ce8aa/tw_cards_compania.png"/>
        <meta property="twitter:creator" content="https://twitter.com/qualoom"/>

        <script type="application/ld+json">
        {`{"@type": "BreadcrumbsList", "@id":"https://www.qualoom.es/compania/#breadcrumb",              
            "itemListElement":[
              {"@type":"ListItem", "position":"1", "name":"Inicio", "item": {"@id": "https://www.qualoom.es/", "name": "Inicio"}},
              {"@type":"ListItem", "position":"2", "name":"Compañía", "item": {"@id": "https://www.qualoom.es/compania/", "name": "Compañía"}}
            ]}`}
        </script>
      </Helmet>
      <BackgroundImage fluid={data.image.nodes[0].childImageSharp.fluid}>
        <div className="background-compania">
          <div className="header_div"><Header /></div>
          <div className="main">
            <div className="empty"></div>
            <div className="text">
              <h1>Descubre una nueva manera de innovar</h1>
              <p>En Qualoom Expertise Technology somos Partner Advanced en AWS con servicios DevOps, SysOps, SysAdmin, migración a la nube, Cloud híbrida, optimización de rendimiento y escalabilidad.</p>
            </div>
          </div>
        </div>
      </BackgroundImage>
      <div className="casos-content-main">
        <div className="casos-content">
            <div className="casos-text">
              <h2>Compañía</h2>
              <p>Una compañía capaz de ayudar a más de 200 empresas a impulsar su negocio con nuestras soluciones de valor: DevOps, SysOps, SysAdmin, Infraestructuras altamente escalables y altamente disponibles, proyectos de migración a la nube, Cloud híbrida y Consultoría tecnológica, nos permiten estar en la liga de las compañías más demandas de servicios IT de valor añadido y pertenecer a la red de socios de empresas como Amazon Web Services como <b>Partner Advanced AWS, Microsoft y Google</b>.</p>
            </div>
            <div className="casos-image compania"></div>
        </div>
        <div className="casos-content equipo-mobile">
            <div className="casos-image equipo"></div>
            <div className="casos-text">
              <h2>Nuestro equipo</h2>
              <p>Un equipo comprometido con la excelencia y orientado a resultados. Ponemos a disposición de nuestros socios y clientes el conocimiento y experiencia de más de 10 años en un sector tan dinámico como el de las tecnologías de la información avalado por certificaciones oficiales de los proveedores más importantes como AWS, Microsoft, DELL, HP, CISCO. <br /> Un equipo con los mejores profesionales en constante formación, investigación y desarrollo gracias a programas I+d+i que nos permiten estar en el momento y sitio adecuado.</p>
            </div>
        </div>
        <div className="casos-content">
            <div className="casos-text">
              <h2>Seguridad y Certificaciones</h2>
              <p>Toda nuestra actividad se realiza bajo estrictas medidas de seguridad y cumplimento de los estándares internacionales tales como PCI DSS o la RGPD. Además, implementamos métodos y procedimientos de carácter interno para que nuestros equipos de trabajo desarrollen su actividad en entornos ágiles que permitan una interacción segura, confidencial y consistente con nuestra red de socios y clientes.</p>
            </div>
            <div className="casos-image seguridad"></div>
        </div>
      </div>
      <div className="text-cards"><h2>Testimonios</h2></div>
      <div className="cards-parent"><Display logos={data.logos}/></div>
      <Footer />
    </div>
  )
}

export default Empleo
