import React from "react"
import ReactInterval from 'react-interval';
import Logo from './logo'

function CardData() {
  const rtn = [{
      title: "Interflora",
      desc: ` Nuestra experiencia en Interflora con Qualoom fue altamente gratificante. Antes de contratar sus servicios nuestra infraestructura era realmente deficiente y un verdadero lastre para el desarrollo de la empresa, comparar lo que teníamos con lo que tenemos actualmente, es como comparar la noche con el día.
  Actualmente gracias a Qualoom todos aquellos problemas han quedado en el olvido y ello supone mantener un desarrollo eficaz para nuestra empresa, siempre con la confianza y la seguridad de saber que en caso de ocurrir cualquier problema, por pequeño que sea, los chicos de Qualoom lo solventarán en el menor tiempo posible.
  Más allá de contratar un servicio magnífico, también contratamos en función de saber que nos proporcionan un trato personalizado, generando una confianza que dentro de este sector, es lo que nos genera más valor.
       

  Gustavo Fernández, CIO
       `,
      image: "interflora"
    }, {
      title: "Boston Servicios",
      desc: ` Boston Medical Group se caracteriza por dar un servicio atento y distinguido a nuestros clientes, un trato altamente personalizado, por ello depositamos en Qualoom el proceso de integrar nuestra infraestructura en la nube.
  Consideramos que nuestra asociación con Qualoom fue un paso adelante en nuestro desarrollo y nuestras ambiciones, consolidando nuestra infraestructura en todas las zonas geográficas donde estamos presentes, y siempre con Qualoom como soporte y seguridad de nuestro desarrollo..
      

  Manuel Cerezo, CIO
      `,
      image: "boston"
    }, {
      title: "Tiendanimal",
      desc: ` En Tiendanimal no tenemos ninguna duda de que el equipo de Qualoom aporta la experiencia y profesionalidad que necesita una compañía que se precie. El servicio que prestan está indiscutiblemente a la altura de una gran infraestructura como la que tenemos con nuestro hosting, ya sea de manera proactiva, con sus labores de mantenimiento e innovación de nuestros sistemas, o reactiva, siempre que hemos necesitado su intervención frente a problemas de las más diversas naturalezas, además de ayudarnos a tomar las decisiones más acertadas siempre que lo hemos necesitado. 
  
  Es muy cómodo y enriquecedor trabajar con Qualoom.
  
      `,
      image: "tiendanimal"
    }, {
      title: "MYMOID",
      desc: ` Desde MYMOID hemos encontrado en Qualoom un proveedor estratégico de cara a nuestro crecimiento de compañía, gracias a su soporte 24/7 y equipo técnico hemos podido solucionar incidencias y plantear arquitecturas innovadoras. Su profesionalidad, excelencia y disponibilidad son algunas de las características que siempre han estado de manifiesto en los proyectos. Personalmente, a la hora de elegir un partner la calidad humana de su equipo es un factor diferencial y Qualoom la tiene.
      
      
  Antonio Javier, CEO
      `,
      image: "mymoid"
    }]
  return rtn;
}

class Cards extends React.Component {
  render() {
    const cardData = CardData();
    return (
      <section>
      {
        cardData.map((card, i) => {
          return (
            <div className="card2" id="card" style={this.props.cardStyle} key={i}>
              <p className="title">{card.title}</p>
              <p className="desc">{card.desc}</p>
              <Logo filename={card.image} alt={card.image}/>
            </div>
          )
        })
      }
      </section>
    )
  }
}

class Display extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentCard: 0,
      position: 0,
      cardStyle: {
        transform: 'translateX(0px)'
      },
      width: 0,
      enabled: true
    };
  }
  
  changeCard(type) {
    let boxWidth = document.getElementById("card").clientWidth;
    let marginLeft = window.getComputedStyle(document.getElementById("card")).marginLeft;
    marginLeft = JSON.parse(marginLeft.replace(/px/i, '')); 
    let marginRight = window.getComputedStyle(document.getElementById("card")).marginRight;
    marginRight = JSON.parse(marginRight.replace(/px/i, '')); 
    this.setState({ 
      width: boxWidth,
      marginLeft: marginLeft,
      marginRight: marginRight,
    })
    const cardWidth = boxWidth; // the card's width
    const cardMargin = marginLeft+marginRight; // the card's margin
    const cardNumber = CardData().length; // the number of cards
    let currentCard = this.state.currentCard; // the index of the current card
    let position = this.state.position; // the position of the cards
    
    // slide cards
    if(type === 'next' && currentCard < cardNumber-1) {
      currentCard++;
      position -= (cardWidth+cardMargin);
    }else if (type === 'next' && currentCard === cardNumber-1) {
      currentCard=0;
      position = 0
    }else if(type === 'prev' && currentCard > 0) {
      currentCard--;
      position += (cardWidth+cardMargin);
    }else if(type === 'prev' && currentCard === 0) {
      currentCard=cardNumber-1;
      position = (currentCard*(cardWidth+cardMargin))*-1;
    }
    this.setCard(currentCard, position);
  }
  
  setCard(currentCard, position) {
    this.setState({
      currentCard: currentCard,
      position: position,
      cardStyle: {
        transform: `translateX(${position}px)`
      }
    })
  }
  
  onResize = () => {
    let boxWidth = document.getElementById("card").clientWidth;
    let marginLeft = window.getComputedStyle(document.getElementById("card")).marginLeft;
    marginLeft = JSON.parse(marginLeft.replace(/px/i, '')); 
    let marginRight = window.getComputedStyle(document.getElementById("card")).marginRight;
    marginRight = JSON.parse(marginRight.replace(/px/i, '')); 
    this.setState({ 
      width: boxWidth,
      marginLeft: marginLeft,
      marginRight: marginRight,
    })
    this.setCard(0, 0);
  }

  // func: click the slider buttons
  handleClick(type) {
    this.setState({enabled: false})
    // get the card's margin-right
    this.changeCard(type)
  }

  nextSlide(){
    this.changeCard('next')
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    let boxWidth = document.getElementById("card").clientWidth;
    this.setState({ width: boxWidth });
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }


  render() {
    const {enabled} = this.state;
    return (
      <>
        <ReactInterval 
        {...{enabled}}
        timeout={7000} 
        callback={() => this.nextSlide() } />
        <div className="cards2-slider">
          <div className="slider-btns">
            <button className="slider-btn btn-l" onClick={() => this.handleClick('prev')} onKeyDown aria-hidden>&lt;</button>
            <button className="slider-btn btn-r" onClick={() => this.handleClick('next')} onKeyDown aria-hidden>&gt;</button>
          </div>
          <Cards cardStyle={this.state.cardStyle} />
        </div>
      </>
    )
  }
}


export default Display