import React from "react"
import Img from 'gatsby-image'
import { graphql, StaticQuery} from "gatsby"


const Logo = (props) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile (
          filter: { relativeDirectory: { eq: "offering/logos" } }
          ) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                sizes(maxWidth: 600) {
                  ...GatsbyImageSharpSizes
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const image = data.images.edges.find(n => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) { return null; }
      
      const imageSizes = image.node.childImageSharp.sizes;
      return (
        <Img
          className="review-logo"
          alt={props.alt}
          sizes={imageSizes}
        />
      );
    }}
  />
)

export default Logo